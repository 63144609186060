<template>
    <div class="more">
        <headBack>
            <template v-slot:title>
                <div class='title'>
                    更多服务
                </div>
            </template>
        </headBack>
        <div class='header_cont'></div>
<!--        <div class='list_content'>-->
<!--            <div v-for='(item,index) in list' :key='index' @click='toDetail(item.roleCode,item.scdSvCode,item.firSvCode)'>-->
<!--                <img :src='changetText(item.logo)' class='icon'/>-->
<!--                <div>{{item.serviceName}}</div>-->
<!--            </div>-->
<!--        </div>-->

        <van-grid :column-num="3" :border="false">
            <van-grid-item
                    v-for='(item,index) in list'
                    :key='index'
                    icon-size="30"
                    :icon="changetText(item.img)"
                    :text="item.text"
                    @click='toDetail(item.roleCode,item.scdSvCode,item.firSvCode)'
            />
        </van-grid>
    </div>
</template>
<script>
import { onMounted, ref, computed } from "vue"
import { useRouter, useRoute } from 'vue-router'
import { useStore } from 'vuex'
import homeApi from '@axios/home'


export default {
  name: 'more',
  components: {


  },
  setup () {
    let router = useRouter()
    const store = useStore()
    const route = useRoute()
    let list = ref([]);

    const Recommenduserphone = route.query.Recommenduserphone ?  route.query.Recommenduserphone : '' //护士任务参数 手机号
    const batchNumber = route.query.batchNumber ?  route.query.batchNumber : '' //护士任务参数

    const getList = () => {
        homeApi.getMoreList().then((res) => {
            list.value = res.data;
        })
    }

    getList();

    const changetText = (val) => {
        var reg = RegExp(/http/);
        if(reg.test(val)){
            return 'https://' + val.split('//')[1]
        }else{
            return  val
        }
    }

    const toDetail = (roleCode,scdSvCode,firSvCode) => {

      if(roleCode == '019'){
        router.push({
          name: 'serviceDetail',
          query: {
            rolecode: roleCode,
            firsvcode: firSvCode,
            scdsvcode: scdSvCode,
            Recommenduserphone:Recommenduserphone,
            batchNumber:batchNumber
          }
        })
      }else{
        router.push({
          name : 'goodsDetail',
          query : {
            rolecode : roleCode,
            scdcvcode : scdSvCode,
            firsvcode : firSvCode,
            Recommenduserphone:Recommenduserphone,
            batchNumber:batchNumber
          }
        })
      }

    }

    return {
        toDetail,
        changetText,
        list,
        router
    }
  },

}
</script>
<style rel="stylesheet/scss" lang="scss" scoped>
    ::v-deep(.van-grid-item__text){
        font-size: 28px;
        color:#313131;
        padding:0 25px;
    }
    ::v-deep(.van-icon__image){
        width: 80px;
        height: 80px;
    }
    .list_content{
        margin-left:30px;
        margin-right:30px;
        /*padding-top: 40px;*/
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        &>div{
            width: 33.3%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin-top:45px;

            /*box-sizing: border-box;*/
            overflow: hidden;
            text-overflow:ellipsis;
            white-space: nowrap;
            div{

                padding: 0px 24px;
                /*overflow: hidden;*/
                /*text-overflow: ellipsis;*/
                /*display: -webkit-box;*/
                /*-webkit-line-clamp: 2;*/
                /*-webkit-box-orient: vertical;*/
                font-size: 28px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #313131;
            }

        }
    }
    .icon{
        width: 88px;
        margin-bottom: 10px;
    }
</style>
